(function() {
    var script = document.createElement('script');
    script.src = 'https://embed.immodan.fi/widget.min.js';
    script.async = true;
    
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://embed.immodan.fi/widget.min.css';
    
    document.head.appendChild(link);
    document.body.appendChild(script);
})();